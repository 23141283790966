.select-menu {
  width: auto;
  position: relative;
}

.select-menu .select-btn {
  display: flex;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.select-btn i {
  font-size: 25px;
  transition: transform 0.3s;
}

.select-menu.active .select-btn i {
  transform: rotate(-180deg);
}

.select-menu .options {
  position: absolute;
  width: max-content;
  z-index: 999;
  padding: 20px;
  /* margin-top: 10px; */
  border-radius: 8px;
  background: #fff;
  display: none;
  transition: opacity 0.3s;
}

.select-menu.active .options {
  display: block;
}

.options .option {
  display: flex;
  height: 55px;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  transition: background 0.3s;
}

.options .option:hover {
  background: #f2f2f2;
}

.option img {
  margin-right: 12px;
}

.option .option-text {
  color: #333;
}
